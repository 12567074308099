import React from "react";
import ReactDOM from "react-dom";
import {db, auth, providers, payments} from "./firebase.js"
import { onAuthStateChanged, signInWithEmailAndPassword, createUserWithEmailAndPassword, signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import { collection, getDocs, query, where, onSnapshot, addDoc, doc } from "firebase/firestore";
import { createCheckoutSession, Stripe } from "@stripe/firestore-stripe-payments";
import { loadStripe } from "@stripe/stripe-js"
import "./App.css"

const stripePromise = loadStripe("pk_test_51KzqqVBc1cAyUoN6y8CclkHnZedVRz4PeUJON3eGXGZbBsZ5SOwHVYUpq9yW1rl1weizxlUQMYu4TKrZ4zl8Vy1D00QzfwbtYs")

const provider = new GoogleAuthProvider();

class Products extends React.Component {
	constructor(props) {
		super(props);
		this.state = {products: undefined}
	}

	async componentDidMount() {
		let products = {};
		let q = query(collection(db, "products"), where("active", "==", true))
		let querySnapshot = await getDocs(q)
		querySnapshot.forEach(async doc => {
			products[doc.id] = doc.data();
			let priceSnapshot = await getDocs(collection(db, "products/" + doc.id + "/prices"));
			priceSnapshot.forEach(price => {
				products[doc.id].prices = price.data();
				products[doc.id].prices.id = price.id;
			})
		})
		this.setState({products: products})
	}

	render() {
		return (
			<div className = "productsContainer">
				{this.state.products &&
					Object.keys(this.state.products).map(product => (
						<div key = {this.state.products[product].id} className = "product">
							<h2>{this.state.products[product].name}</h2>
							<button onClick = {() => this.props.addToCart(this.state.products[product])}>Add To Cart</button>
						</div>
					))
				}
			</div>
		)
	}
}

class Login extends React.Component {
	constructor(props) {
		super(props);
		this.state = {isLogin: true};
		this.handleChangeAccount = this.handleChangeAccount.bind(this);
		this.login = this.login.bind(this);
		this.signup = this.signup.bind(this);
		this.loginWithGoogle = this.loginWithGoogle.bind(this);
	}

	handleChangeAccount() {
		this.setState(prevState => ({
			isLogin: !prevState.isLogin
		}))
	}

	login() {
		signInWithEmailAndPassword(auth, document.getElementById("e").value, document.getElementById("p").value).catch(err => {
			document.getElementById("err").style.display = "block";
			document.getElementById("err").innerHTML = err.message;
		}).then((user => {
			this.props.toggleSignIn();
		}))
	}

	loginWithGoogle() {
		signInWithPopup(auth, provider).catch(err => {
			document.getElementById("err").style.display = "block";
			document.getElementById("err").innerHTML = err.message;
		}).then((user => {
			this.props.toggleSignIn();
		}))
	}

	signup() {
		let e = document.getElementById("se").value;
		let p1 = document.getElementById("sp1").value;
		let p2 = document.getElementById("sp2").value;

		if(p1 === p2) {
			createUserWithEmailAndPassword(auth, e, p1).catch(err => {
				document.getElementById("err").style.display = "block";
				document.getElementById("err").innerHTML = err.message;
			});
			this.handleChangeAccount();
		} else {
			document.getElementById("err").style.display = "block";
			document.getElementById("err").innerHTML = "Passwords Do Not Match"
		}
	}

	render() {
		return (
			<div id = "loginContainer">
				<button onClick = {this.props.toggleSignIn}>X</button>
				{this.state.isLogin
					?<>
						<div id = "err" style={{display: "none"}}></div>
						<div>
							<h1>Email:</h1>
							<input type = "email" id = "e" required></input><br />
							<h1>Password:</h1>
							<input type = "password" id = "p" required></input><br />
							<button className = "loginBtn" onClick = {this.login}>Login!</button>
						</div>
						<button className = "login-with-google-btn" onClick = {this.loginWithGoogle}>Continue With Google</button>
						<p className = "toggleLink" onClick = {this.handleChangeAccount}>Create New Account</p>
					</>
					:<>
						<div id = "err" style={{display: "none"}}></div>
						<div>
							<h1>Email:</h1>
							<input type = "email" id = "se"></input><br />
							<h1>Password:</h1>
							<input type = "password" id = "sp1"></input><br />
							<h1>Confirm Password:</h1>
							<input type = "password" id = "sp2"></input><br />
							<button className = "loginBtn" onClick = {this.signup}>Login!</button>
						</div>
						<button className = "login-with-google-btn" onClick = {this.loginWithGoogle}>Continue With Google</button>
						<p className = "toggleLink" onClick = {this.handleChangeAccount}>Login To Existing Account</p>
					</>
				}
			</div>
			
		)
	}
}

class App extends React.Component {
    constructor(props) {
        super(props);
		this.startPayment = this.startPayment.bind(this);
		this.toggleSignIn = this.toggleSignIn.bind(this);
		this.addItemToCart = this.addItemToCart.bind(this);
		this.state = {makingPayment: false, currentProducts: [], reqLogin: false, productInfo: []}
    }

	toggleSignIn() {
		this.setState(prevState => ({
			reqLogin: !prevState.reqLogin,
			makingPayment: false
		}))
		
		if(this.state.currentProducts.length > 0 && auth.currentUser) {
			this.startPayment();
		}
	}

	addItemToCart(product) {
		let temp = this.state.currentProducts.concat({price: product.prices.id, quantity: 1});
		this.setState({
			currentProducts: temp
		});

		let temp2 = this.state.productInfo.concat(product);
		this.setState({
			productInfo: temp2
		});
	}

	async startPayment() {
		if(this.state.currentProducts.length > 0) {
			this.setState({reqLogin: true, showCart: false});
			if(!this.state.makingPayment && auth.currentUser) {
				this.setState({makingPayment: true});
				const docRef = await addDoc(collection(db, `customers/${auth.currentUser.uid}/checkout_sessions`), {
					mode: 'payment',
					line_items: this.state.currentProducts,
					success_url: window.location.origin,
					cancel_url: window.location.origin
				});

				onSnapshot(docRef, (snap) => {
					const { error, url } = snap.data();
					if (error) {
						alert(`An error occured: ${error.message}`);
					} else if (url) {
						window.location.assign(url);
					}
				})
			}
		}
	}

    render() {
		return(
			<>
				{!auth.currentUser && this.state.reqLogin
					?<Login toggleSignIn = {this.toggleSignIn} />
					:<>
						<div className = "topNav">
							<div className="temp">
								<h1 className = "name">Dumbass Technologies LLC</h1>
								<button onClick={() => this.setState(prevState => ({showCart: !prevState.showCart}))}>Cart</button>
								{auth.currentUser
									?<button onClick={() => auth.signOut()}>Sign out</button>
									:<button onClick = {this.toggleSignIn}>Sign In</button>
								}
							</div>
							<img src = "./bottom-nav-shape.svg"></img>
						</div>
						{this.state.showCart &&
							<div className="cartContainer">
								<button className = "exit" onClick={() => this.setState(prevState => ({showCart: !prevState.showCart}))}>X</button>
								<h1>Cart: </h1>
								<div className="items">
									{this.state.productInfo.map(item => {
										return(
											<div>
												<h6>{item.name}: ${item.prices.unit_amount / 100}</h6>
											</div>
										)
									})}
								</div>
							<button onClick={this.startPayment}>Checkout</button>
						</div>
						}
						<div className = "contentContainer">
							{this.state.makingPayment && auth.currentUser
								?<h1 className = "loading">Loading...</h1>
								:<Products addToCart = {this.addItemToCart} />
							}
						</div>
					</>
				}
			</>
		)
    }
}

onAuthStateChanged(auth, user => {
	ReactDOM.render(
		<App />,
		document.getElementById('root')
	)
})