import { initializeApp } from "firebase/app";
import { getFirestore } from 'firebase/firestore';
import { getAuth, GoogleAuthProvider } from "firebase/auth"
import { getStripePayments } from "@stripe/firestore-stripe-payments";

const config = {
    apiKey: "AIzaSyBFpkEd5geiCr-KytMb0dysAI7ThpJUbFE",
    authDomain: "project-aaf.firebaseapp.com",
    projectId: "project-aaf",
    storageBucket: "project-aaf.appspot.com",
    messagingSenderId: "891021970243",
    appId: "1:891021970243:web:909510e8428878d71bfdf6",
    measurementId: "G-WW3WD6F789"
};

const app = initializeApp(config);

const db = getFirestore(app);

const auth = getAuth();

const providers = [
    new GoogleAuthProvider().providerId
];

const payments = getStripePayments(app, {
	productsCollection: "products",
	customersCollection: "customers"
});

export {db, auth, providers, payments};